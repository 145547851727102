import React, { ReactNode } from 'react';
import { FaStar } from 'react-icons/fa';
import vanjaPaunovic from './testimonialImg/vanja_paunovic.jpg';
import ivanPopJovanov from './testimonialImg/ivan_pop_jovanov.jpg';
import lazarPopovic from './testimonialImg/lazar_popovic.jpg';
import missingProfilePic from './testimonialImg/missing_pic.webp';
import anaGraovac from './testimonialImg/ana_graovac.jpg';
import milosMilunovic from './testimonialImg/milos_milunovic.jpg';
import jelenaGledic from './testimonialImg/jelena_gledic.png';
import stevanGolubovic from './testimonialImg/stevan_golubovic.jpg';
import mihailoStupar from './testimonialImg/mihailo_stupar.jpeg';
import { RoughNotationWrapper } from '../../components/RoughNotationWrapper';

export default function UserTestiomonials() {
  const vanjaPaunovicTestimonial = (
    <UserTestiomonial
      name="Vanja Paunović"
      text={
        <>
          <p>
            I was working for a client and we launched the website live. One
            year later, I forgot to extend the domain and the website{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="went down without me noticing it"
              />
            </b>
            .
          </p>
          <p className="mt-4">
            Luckily,{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="Notify Me detected an outage"
              />
            </b>{' '}
            and sent me an email, so I acted quickly and resolved the issue.
          </p>
          <p className="mt-4">
            <b>
              {' '}
              <RoughNotationWrapper style="underline" text="Amazing product" />
            </b>{' '}
            that has saved me multiple times!
          </p>
        </>
      }
      info="DevRel @ Tenderly"
      imgSrc={vanjaPaunovic}
    />
  );
  const ivanPopJovanovTestimonial = (
    <UserTestiomonial
      name="Ivan Pop-Jovanov"
      text={
        <>
          <p>
            Notifications are{' '}
            <b>
              <RoughNotationWrapper style="underline" text="always on time" />
            </b>
            , and they arrive fast enough.
          </p>
          <p className="mt-4">
            I don&apos;t have to worry about missing an important update from my
            university courses.
          </p>
          <p className="mt-4">
            <b>
              {' '}
              <RoughNotationWrapper
                style="underline"
                text="All notifications are in one place"
              />
            </b>{' '}
            - I just have to check my email inbox so I definitely recommend
            Notify Me.
          </p>
        </>
      }
      info="Student @ University of Belgrade"
      imgSrc={ivanPopJovanov}
    />
  );
  const bisonabloomTestimonial = (
    <UserTestiomonial
      name="u/bisonabloom"
      text={
        <>
          <p>
            I have tried multiple services that track website changes and none
            of them gave me the{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="immediate website updates"
              />
            </b>{' '}
            that Notify Me did.
          </p>
          <p className="mt-4">
            I was able to{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="buy a camera at a discount"
              />
            </b>{' '}
            that I had been wanting for months. This will be my go to website
            change app moving forward!
          </p>
          <p>&nbsp;</p>
        </>
      }
      info="User @ Reddit"
      imgSrc={missingProfilePic}
    />
  );

  const lazarTestimonial = (
    <UserTestiomonial
      name="Lazar Popović"
      text={
        <>
          <p>
            Notify Me helped{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="track the PhD stipend application page"
              />
            </b>
          </p>
          <p className="mt-4">
            I was able to be{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="one of the first to apply"
              />
            </b>
            .
          </p>
          <p className="mt-4">
            Before Notify Me I had to check the page every day, now{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="I get an email whenever the page changes"
              />
            </b>
            .
          </p>
          <p>&nbsp;</p>
        </>
      }
      info="PhD Student @ University of Belgrade"
      imgSrc={lazarPopovic}
    />
  );
  const anaTestimonial = (
    <UserTestiomonial
      name="Ana Graovac"
      text={
        <>
          <p>
            Notify Me has been an amazing tool for me over the past year.
            It&apos;s{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="consistently kept me in the loop"
              />
            </b>{' '}
            about numerous scholarship opportunities, grants, and funding
            options, as well as free courses and webinars.
          </p>
          <p className="mt-4">
            Thanks to its timely notifications,{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="I never miss out on vital information"
              />
            </b>{' '}
            to advance my education and career.
          </p>
          <p className="mt-4">
            And as a PhD student and a science communicator, it is very
            important to stay in the loop!
          </p>
        </>
      }
      info="Head of Biology Department @ Petnica Science Center"
      imgSrc={anaGraovac}
    />
  );
  const milosTestimonial = (
    <UserTestiomonial
      name="Miloš Milunović"
      text={
        <>
          <p>
            I&apos;m using Notify Me to{' '}
            <b>
              {' '}
              <RoughNotationWrapper
                style="underline"
                text="track latest blogs and articles"
              />
            </b>{' '}
            from my favorite authors.
          </p>
          <p className="mt-4">
            <b>
              <RoughNotationWrapper
                style="underline"
                text="I don't have to subscribe to newsletters"
              />
            </b>{' '}
            and get spammed with emails.
          </p>
          <p className="mt-4">
            I just get a notification from Notify Me whenever a new article is
            published.
          </p>
          <p>&nbsp;</p>
        </>
      }
      info="Data and Applied Scientist @ Microsoft"
      imgSrc={milosMilunovic}
    />
  );
  const stevanTestimonial = (
    <UserTestiomonial
      name="Stevan Golubović"
      text={
        <>
          <p>
            Notify Me greatly helped me in business during the global chip
            shortage (during the COVID pandemic). It allowed me to be among the
            first to find out{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="when components are back in stock"
              />
            </b>{' '}
            and place orders.
          </p>
          <p className="mt-4">
            <b>
              <RoughNotationWrapper
                style="underline"
                text="Without Notify Me tracking, I would have had to constantly refresh numerous supplier pages endlessly"
              />
            </b>
            , and I wouldn&apos;t have had time to focus on the engineering
            aspects of the job.
          </p>
          <p>&nbsp;</p>
        </>
      }
      info="CEO @ Instrumenta"
      imgSrc={stevanGolubovic}
    />
  );
  const jelenaGledicTestimonial = (
    <UserTestiomonial
      name="Jelena Gledić"
      text={
        <>
          <p>
            Notify Me has been immensely helpful in{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="saving me time and reducing the mental load"
              />
            </b>{' '}
            of keeping track of reminders to check updates.
          </p>
          <p className="mt-4">
            <b>
              <RoughNotationWrapper
                style="underline"
                text="I use it to keep an eye on new open calls for projects, events, etc."
              />
            </b>
            , on the websites of various organizations and institutions, which
            are delivered straight to my inbox.
          </p>
          <p className="mt-4">
            It feels great to be in the loop without effort and know you won’t
            miss out on opportunities!
          </p>
          <p>&nbsp;</p>
        </>
      }
      info="Senior Instructor @ University of Belgrade"
      imgSrc={jelenaGledic}
    />
  );
  const mihailoStuparTestimonial = (
    <UserTestiomonial
      name="Mihailo Stupar"
      text={
        <>
          <p>
            I&apos;m using Notify Me to get{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="alerts about possible water and power outages"
              />
            </b>{' '}
            in my area.
          </p>
          <p className="mt-4">
            <b>
              <RoughNotationWrapper
                style="underline"
                text="Thanks to Notify Me, I was prepared in advance"
              />
            </b>{' '}
            and I could plan my day accordingly knowing when the outages would
            occur.
          </p>
          <p>&nbsp;</p>
        </>
      }
      info="Software Engineer @ Tenderly"
      imgSrc={mihailoStupar}
    />
  );

  // eslint-disable-next-line no-unused-vars
  const warmbeerandcoldpizzaTestimonial = (
    <UserTestiomonial
      name="u/warmbeerandcoldpizza"
      text={
        <>
          <p>
            I saw Notify Me on reddit post and decided to give it a try.{' '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="Notify Me alerted me of an available refurbed camera"
              />
            </b>{' '}
            in my area.
          </p>
          <p className="mt-4">
            <b>
              <RoughNotationWrapper
                style="underline"
                text="I was able to purchase it before they ran out of stock."
              />
            </b>{' '}
          </p>
          <p className="mt-4">Thanks for the tool!</p>
          <p>&nbsp;</p>
        </>
      }
      info="User @ Reddit"
      imgSrc={missingProfilePic}
    />
  );

  const elmendiHnTestimonial = (
    <UserTestiomonial
      name="Elmendi Hn"
      text={
        <>
          <p className="mt-4">
            {'This service is working very great it '}
            <b>
              <RoughNotationWrapper
                style="underline"
                text="helped me a lot and saved me a lot of time"
              />
            </b>
          </p>
          <p>&nbsp;</p>
          <p className="mt-4">{'Thank u sooo much love u guys <3'}</p>
          <p>&nbsp;</p>
        </>
      }
      info="User @ Notify Me"
      imgSrc={missingProfilePic}
    />
  );

  return (
    <div className="max-w-6xl p-4 m-auto">
      <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
        What Some Happy Users Have To Say...
      </p>
      <div className="lg:grid lg:grid-cols-3 lg:gap-12">
        {vanjaPaunovicTestimonial}
        {ivanPopJovanovTestimonial}
        {bisonabloomTestimonial}
        {lazarTestimonial}
        {anaTestimonial}
        {milosTestimonial}
        {jelenaGledicTestimonial}
        {stevanTestimonial}
        {mihailoStuparTestimonial}
        {warmbeerandcoldpizzaTestimonial}
        {elmendiHnTestimonial}
      </div>
    </div>
  );
}

// Steva, Moki, Jelena Gledic, Filip Filipovic, Tata,

interface Props {
  name: string;
  text: ReactNode;
  info: string;
  imgSrc: string;
}

export function UserTestiomonial(props: Props) {
  return (
    <div className="mx-auto bg-main-backgroundFooter rounded-xl p-4 my-8">
      <div className="flex text-center">
        <div className="m-auto flex text-yellow-400">
          <FaStar className="mx-0.5" />
          <FaStar className="mx-0.5" />
          <FaStar className="mx-0.5" />
          <FaStar className="mx-0.5" />
          <FaStar className="mx-0.5" />
        </div>
      </div>
      <div className="p-4">
        <p className="block text-sm">{props.text}</p>
      </div>
      <div className="grid grid-cols-3">
        <img
          className="w-16 rounded-full mx-auto"
          src={props.imgSrc}
          alt={`${props.name} image`}
        />
        <div className="text-sm col-span-2">
          <p className="text-main-letters">{props.name}</p>
          <p className="text-main-footerLinks">{props.info}</p>
        </div>
      </div>
    </div>
  );
}
