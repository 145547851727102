/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import LoadingBar from '../../components/LoadingBar';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as api from '../../api';
import { PlanId, TrackedSite } from '../../types';
import { EventType, registerEvent } from '../../plausible';
import HelmetComponent from '../../components/HelmetComponent';
import { useFormik } from 'formik';
import { addNewSiteValidationSchema } from '../AddNewSite/validation';
import TextArea from '../../components/TextArea';
import useApiCall from '../../hooks/UseApiCall';
import InputField from '../../components/InputField';
import CheckIntervalDropdown, {
  PlanToIntervalMap,
} from '../../components/CheckIntervalDropdown';
import { useBillingInfo } from '../../context/BillingInfoContext';

export default function EditSite() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);
  const [currentlyEditedSite, setCurrentlyEditedSite] = useState<TrackedSite>();
  const [keywordsEnabled, setKeywordsEnabled] = useState(false);
  const location = useLocation() as any;

  const { billingInfo } = useBillingInfo();

  const currentPlan =
    billingInfo && billingInfo.id ? billingInfo.PlanId : PlanId.DEFAULT;
  const [scrapeInterval, setScrapeInterval] = useState<number>(
    PlanToIntervalMap[currentPlan],
  );

  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  const formik = useFormik({
    initialValues: {
      //? It's possible to load page directly from url
      //? this ternary operator is here to ensure that nothing will crash
      //? until redirect to /overview has happened
      siteName:
        location && location.state ? (location.state.siteName as string) : '',
      siteUrl:
        location && location.state ? (location.state.siteUrl as string) : '',
      keywords:
        location && location.state ? (location.state.keywords as string) : '',
    },
    validationSchema: addNewSiteValidationSchema,
    onSubmit: async (values) => {
      const keywordsForSending = keywordsEnabled ? values.keywords : '';
      onConfirm(values.siteName, values.siteUrl, keywordsForSending);
    },
  });

  useEffect(() => {
    setWaitingForServerResponse(true);
    if (!location.state) {
      navigate('/overview');
      return;
    }
    setCurrentlyEditedSite(location.state);
    setScrapeInterval(location.state.scrapeInterval);

    setKeywordsEnabled(
      location &&
        location.state &&
        location.state.keywords &&
        location.state.keywords !== '',
    );
    setWaitingForServerResponse(false);
  }, []);

  const onConfirm = async (
    siteName: string,
    siteUrl: string,
    keywords: string,
  ) => {
    const response = await apiCall(
      api.updateSiteInfo(
        siteUrl,
        currentlyEditedSite?.siteUrl as string,
        siteName,
        keywords,
        scrapeInterval,
      ),
      'Edit successful',
    );

    if (response.error) {
      return;
    }

    registerEvent(EventType.EDIT_SITE_CONFIRM);
    navigate('/overview');
  };

  if (waitingForServerResponse) {
    return (
      <div className="m-auto text-center mt-28">
        <LoadingBar isLoading={true} />
      </div>
    );
  }

  return (
    <div className="">
      <HelmetComponent
        title="Edit new site | Notify Me"
        description="Edit site page for Notify Me."
        canonicalLink="/edit_site"
      />
      <div className="flex lg:min-h-screen justify-center items-center">
        <form
          className="bg-main-footerLinks bg-opacity-40 p-8 border rounded-xl border-main-letters max-w-sm w-full"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center">
              Edit Site
            </h1>
          </div>
          <InputField
            label="site url"
            id="siteUrl"
            type="siteUrl"
            name="siteUrl"
            value={formik.values.siteUrl}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.siteUrl && formik.errors.siteUrl
                ? formik.errors.siteUrl
                : undefined
            }
          />
          <InputField
            label="site name"
            id="siteName"
            type="siteName"
            name="siteName"
            value={formik.values.siteName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.siteName && formik.errors.siteName
                ? formik.errors.siteName
                : undefined
            }
          />
          <div className="mt-8">
            <input
              type="checkbox"
              id="checkbox"
              className="mr-2"
              checked={keywordsEnabled}
              onClick={() => setKeywordsEnabled(!keywordsEnabled)}
            />
            <label htmlFor="checkbox" className="text-sm">
              I want to track only changes with specific words
            </label>
          </div>
          <div className={keywordsEnabled ? '' : 'hidden'}>
            <TextArea
              rows={4}
              placeholder="Input keywords you want to track separated by space"
              label="keywords"
              id="keywords"
              name="keywords"
              value={formik.values.keywords}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.keywords && formik.errors.keywords
                  ? formik.errors.keywords
                  : undefined
              }
            />
          </div>
          <div className="mt-4">
            <CheckIntervalDropdown
              scrapeInterval={scrapeInterval}
              setScrapeInterval={setScrapeInterval}
            />
          </div>
          <div className="mt-4">
            <button className="btn btn-block btn-primary">Confirm</button>
          </div>
          <div className="mt-4 text-center">
            <LoadingBar isLoading={waitingForServerResponse} />
          </div>
        </form>
      </div>
    </div>
  );
}
