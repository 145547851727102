import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddNewSite from '../../pages/AddNewSite';
import EditSite from '../../pages/EditSite';
import LandingPage from '../../pages/LandingPage';
import LoginPage from '../../pages/LoginPage';
import OverviewPage from '../../pages/OverviewPage';
import SignupPage from '../../pages/SignupPage';
import PrivateRoute from '../PrivateRoute';
import RedirectIfLoggedInRoute from '../RedirectIfLoggedInRoute';
import ConfirmRegistration from '../../pages/ConfirmRegistration';
import ResetPassword from '../../pages/ResetPassword';
import ConfirmResetPassword from '../../pages/ConfirmResetPassword';
import DeleteAccount from '../../pages/DeleteAccount';
import SendReview from '../../pages/SendReview';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import TermsOfUse from '../../pages/TermsOfUse';
import Pricing from '../../pages/Pricing';
import HowItWorks from '../../pages/HowItWorks';
import AccountSettings from '../../pages/AccountSettings';
import PageNotFound from '../../pages/PageNotFound';
import SidebarWrapper from '../SidebarWrapper';
import ChangeFeedback from '../../pages/ChangeFeedback';
import AddPackage from '../../pages/AddPackage';
import DiffPreview2 from '../../pages/DiffPreview2';
import Blog from '../../pages/Blog';
import SidebarIfLoggedIn from '../SidebarIfLoggedIn';
import AffiliateProgram from '../../pages/AffiliateProgram';
import RefundPolicy from '../../pages/RefundPolicy';

export default function Pages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RedirectIfLoggedInRoute>
            <LandingPage />
          </RedirectIfLoggedInRoute>
        }
      />

      <Route
        path="/login"
        element={
          <RedirectIfLoggedInRoute>
            <LoginPage />
          </RedirectIfLoggedInRoute>
        }
      />

      <Route
        path="/signup"
        element={
          <RedirectIfLoggedInRoute>
            <SignupPage />
          </RedirectIfLoggedInRoute>
        }
      />

      <Route
        path="/overview"
        element={
          <PrivateRoute>
            <SidebarWrapper>
              <OverviewPage />
            </SidebarWrapper>
          </PrivateRoute>
        }
      />

      <Route
        path="/add_new_site"
        element={
          <PrivateRoute>
            <SidebarWrapper>
              <AddNewSite />
            </SidebarWrapper>
          </PrivateRoute>
        }
      />

      <Route
        path="/edit_site"
        element={
          <PrivateRoute>
            <SidebarWrapper>
              <EditSite />
            </SidebarWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path="/confirm_registration/:token"
        element={
          <RedirectIfLoggedInRoute>
            <ConfirmRegistration />
          </RedirectIfLoggedInRoute>
        }
      />

      <Route
        path="/reset_password"
        element={
          <RedirectIfLoggedInRoute>
            <ResetPassword />
          </RedirectIfLoggedInRoute>
        }
      />

      <Route path="/reset_password/:token" element={<ConfirmResetPassword />} />

      <Route
        path="/delete_account"
        element={
          <PrivateRoute>
            <SidebarWrapper>
              <DeleteAccount />
            </SidebarWrapper>
          </PrivateRoute>
        }
      />

      <Route
        path="/send_review"
        element={
          <PrivateRoute>
            <SidebarWrapper>
              <SendReview />
            </SidebarWrapper>
          </PrivateRoute>
        }
      />

      <Route
        path="/privacy_policy"
        element={
          <SidebarIfLoggedIn>
            <PrivacyPolicy />
          </SidebarIfLoggedIn>
        }
      />

      <Route
        path="/refund_policy"
        element={
          <SidebarIfLoggedIn>
            <RefundPolicy />
          </SidebarIfLoggedIn>
        }
      />

      <Route
        path="/terms_of_use"
        element={
          <SidebarIfLoggedIn>
            <TermsOfUse />
          </SidebarIfLoggedIn>
        }
      />

      <Route
        path="/pricing"
        element={
          <SidebarIfLoggedIn>
            <Pricing />
          </SidebarIfLoggedIn>
        }
      />

      <Route
        path="/how_it_works"
        element={
          <SidebarIfLoggedIn>
            <HowItWorks />
          </SidebarIfLoggedIn>
        }
      />

      <Route
        path="/account_settings/*"
        element={
          <PrivateRoute>
            <SidebarWrapper>
              <AccountSettings />
            </SidebarWrapper>
          </PrivateRoute>
        }
      />

      <Route path="/history2" element={<DiffPreview2 />} />

      <Route path="/change_feedback" element={<ChangeFeedback />} />

      <Route
        path="/add_package/*"
        element={
          <PrivateRoute>
            <SidebarWrapper>
              <AddPackage />
            </SidebarWrapper>
          </PrivateRoute>
        }
      />

      <Route path="*" element={<PageNotFound />} />
      <Route path="/blog/*" element={<Blog />} />

      <Route path="/earn" element={<AffiliateProgram />} />
    </Routes>
  );
}
