import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/AuthContext';
import * as auth from './auth';
import FooterComponent from './components/FooterComponent';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Pages from './components/Pages';
import useQueryParam from './hooks/UseQueryParam';
import LemonSqueezy from './components/LemonSqueezy';
import { BillingInfoProvider } from './context/BillingInfoContext';
import { BillingInfo } from './types';
import * as api from './api';

export default function App() {
  const [billingInfo, setBillingInfo] = useState<BillingInfo | undefined>();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParam = useQueryParam();

  useEffect(() => {
    (async () => {
      if (!auth.isAuthenticated()) {
        return;
      }
      const response = await api.getBillingInfo();
      if (!response.ok || !response.data) {
        return;
      }
      const info = new BillingInfo(response.data);
      setBillingInfo(info);
    })();
  }, []);

  const logoutUser = (
    navigatePath: string,
    shouldHaveNext: boolean = false,
  ) => {
    if (shouldHaveNext) {
      navigatePath = `${navigatePath}?next=${location.pathname}`;
    }
    auth.clearStorage();
    navigate(navigatePath);
  };

  const loginUser = (accessToken: string) => {
    auth.setAccessToken(accessToken);

    const packageName = queryParam('package');
    if (packageName !== '') {
      navigate(`/add_package?package=${packageName}`);
      return;
    }
    const nextUrl = queryParam('next');
    if (nextUrl == '') {
      navigate('/overview');
      return;
    }
    navigate(nextUrl);
  };

  return (
    <div className="font-default w-full flex flex-grow flex-wrap bg-main-background text-white">
      <BillingInfoProvider value={{ billingInfo }}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        >
          <AuthProvider
            value={{
              logoutUser: logoutUser,
              loginUser: loginUser,
            }}
          >
            <HelmetProvider>
              <ScrollToTop />
              <div className=" lg:min-h-screen w-full">
                <div>
                  <Navbar />
                </div>
                <div className="">
                  <LemonSqueezy>
                    <Pages />
                  </LemonSqueezy>
                </div>
              </div>
              <div className="w-full m-auto">
                <FooterComponent />
              </div>
            </HelmetProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </BillingInfoProvider>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 8500,
        }}
      />
    </div>
  );
}
