import * as errors from './index';

const serverErrors = {
  INVALID_TOKEN: 'Provided data is invalid or expired.',
  INVALID_FORMAT: 'Provided data is in invalid format.',
  ENDPOINT_CALLS_EXCEEDED:
    "We've already sent you several emails, please check your inbox (and your spam), or try again later.",
  MAILER_ERROR: 'Error while sending email, please try again later.',
  WRONG_EMAIL_PASSWORD: 'Wrong email or password.',
  NOT_VERIFIED: 'Please verify your account.',
  USER_NOT_FOUND: "Target user doesn't exist.",
  SITE_NOT_FOUND: "Target site doesn't exist.",
  PACKAGE_NOT_FOUND: "Target package doesn't exist.",
  INVALID_EMAIL_FORMAT: errors.INVALID_EMAIL_FORMAT,
  INVALID_PASSWORD_FORMAT: errors.INVALID_PASSWORD_FORMAT,
  PLAN_LIMIT_REACHED_SITES:
    "You've reached the limit of sites. Plase upgrade your plan.",
  PLAN_LIMIT_REACHED_NOTIFICATIONS:
    "You've reached the limit of notification channels. Plase upgrade your plan.",
  TELEGRAM_NOT_SET_UP:
    "You haven't set up Telegram notifications yet. Please set them up first.",
  INVALID_SCRAPE_INTERVAL:
    'Please upgrade your plan in order to set this interval.',
};

type ErrorKey = keyof typeof serverErrors;

export const DEFAULT_ERROR =
  'There has been an error on our side, please try again later.';

export const determineServerError = (
  errorKey: ErrorKey | undefined,
): string => {
  if (!errorKey || !serverErrors[errorKey]) {
    return DEFAULT_ERROR;
  }
  return serverErrors[errorKey];
};
