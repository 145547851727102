/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import * as api from '../../api';
import HelmetComponent from '../../components/HelmetComponent';
import { useNavigate } from 'react-router';
import LoadingBar from '../../components/LoadingBar';
import { EventType, registerEvent } from '../../plausible';
import { useAuth } from '../../context/AuthContext';
import useApiCall from '../../hooks/UseApiCall';
import { useBillingInfo } from '../../context/BillingInfoContext';
import { toast } from 'react-hot-toast';
import { DEFAULT_ERROR } from '../../errors/determineServerError';
import { BillingInfo, PlanId } from '../../types';
import { Field, Form, Formik } from 'formik';
export default function DeleteAccount() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const navigate = useNavigate();
  const authContext = useAuth();
  const apiCall = useApiCall(setWaitingForServerResponse);
  const { billingInfo } = useBillingInfo();
  const [otherChecked, setOtherChecked] = useState(false);

  const onCancel = () => {
    registerEvent(EventType.DELETE_ACCOUNT_CANCEL);
    navigate('/account_settings/my_account');
    return;
  };

  if (waitingForServerResponse) {
    return (
      <div className="nm-default-page min-h-screen">
        <HelmetComponent
          title="Delete Account | Notify Me"
          description="Page for acccount deletion."
          canonicalLink="/delete_account"
        />
        <div className="m-auto text-center mt-28">
          <LoadingBar isLoading={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="nm-default-page min-h-screen">
      <HelmetComponent
        title="Delete Account | Notify Me"
        description="Page for acccount deletion."
        canonicalLink="/delete_account"
      />
      <div className="bg-main-backgroundFooter p-8 rounded-2xl max-w-2xl">
        <div className="text-left mb-3">
          <Formik
            initialValues={{
              picked: '',
              other: '',
            }}
            onSubmit={async (values) => {
              if (values.picked === '') {
                return toast.error('Please select a reason for deletion');
              }
              if (values.picked === 'Other' && values.other.trim() === '') {
                return toast.error('Please specify the reason for deletion');
              }

              const deletionReason =
                values.picked === 'Other' ? values.other : values.picked;

              const response = await apiCall(
                api.deleteAccount(deletionReason),
                "You've successfully deleted your account!",
              );

              if (response.error) {
                return;
              }

              registerEvent(EventType.DELETE_ACCOUNT_CONFIRM);
              authContext.logoutUser('/', false);
            }}
          >
            {({ values }) => (
              <Form>
                <p className="font-bold font-heading text-center text-4xl text-main-letters">
                  Delete account
                </p>
                <div id="my-radio-group">
                  <p className="text-lg">Choose deletion reason:</p>
                </div>
                <div
                  role="group"
                  aria-labelledby="my-radio-group"
                  className="block"
                >
                  <label className="flex mt-2">
                    <Field
                      type="radio"
                      name="picked"
                      value="I don't need the service anymore"
                      onClick={() => setOtherChecked(false)}
                    />
                    <span className="ml-1">
                      I don&apos;t need the service anymore
                    </span>
                  </label>
                  <label className="flex mt-2">
                    <Field
                      type="radio"
                      name="picked"
                      value="Updates I receive are not relevant"
                      onClick={() => setOtherChecked(false)}
                    />
                    <span className="ml-1">
                      Updates I receive are not relevant
                    </span>
                  </label>
                  <label className="flex mt-2">
                    <Field
                      type="radio"
                      name="picked"
                      value="Updates are too spammy"
                      onClick={() => setOtherChecked(false)}
                    />
                    <span className="ml-1">Updates are too spammy</span>
                  </label>
                  <div className="mt-2">
                    <div>
                      <Field
                        type="radio"
                        name="picked"
                        value="Other"
                        onClick={() => setOtherChecked(true)}
                      />
                      <span className="ml-1">Other</span>
                    </div>
                    {otherChecked ? (
                      <Field
                        name="other"
                        component="textarea"
                        placeholder="Please specify"
                        className="rounded-lg p-2 text-black mt-2"
                        rows={4}
                      />
                    ) : null}
                  </div>
                </div>
                <p className="mt-5">
                  This action{' '}
                  <span className="font-bold text-main-letters">
                    cannot be undone
                  </span>{' '}
                  and you will lose{' '}
                  <span className="font-bold text-main-letters">all</span> your
                  data and access to your account.
                </p>
                {billingInfo &&
                billingInfo.isPaidPlan() &&
                !billingInfo.isLifetimePlan() ? (
                  <p className="mt-5">
                    Since you are on{' '}
                    <span className="font-bold text-main-letters">
                      {billingInfo.name}
                    </span>{' '}
                    you will be charged one more time at{' '}
                    <span className="font-bold text-main-letters">
                      {billingInfo.renewsAt}
                    </span>{' '}
                    before subscription is cancelled completely.
                  </p>
                ) : (
                  <></>
                )}
                <div className="grid lg:grid-cols-12 mt-4 text-center">
                  <div className="mb-4 lg:mb-0 lg:col-start-1 lg:col-span-5">
                    <button
                      className="btn btn-success btn-wide"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="lg:col-start-7 lg:col-span-6">
                    <button className="btn btn-error btn-wide " type="submit">
                      I understand, delete my account
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <LoadingBar isLoading={waitingForServerResponse} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
