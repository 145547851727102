import React from 'react';
import HelmetComponent from '../../components/HelmetComponent';

export default function RefundPolicy() {
  return (
    <div className="">
      <HelmetComponent
        title="Refund Policy | Notify Me"
        description="Refund policy page for Notify Me."
        canonicalLink="/refund_policy"
      />
      <div className="p-8 lg:px-56 lg:py-10 mt-14 lg:mt-2 text-left text-main-footerLinks">
        <h1 className="nm-default-page-heading">Refund Policy for Notify Me</h1>
        <div className="mt-8">
          <div>
            <p className="mt-4 font-bold">
              Our Return and Refund Policy was last updated on 04/01/2025.
            </p>
            <p className=" mt-4">Thank you for shopping at Notify Me.</p>
            <p className=" mt-4">
              The following terms are applicable for any products that you have
              purchased from us.
            </p>
          </div>
          <div>
            <p className="mt-4 font-bold">Eligibility for Refunds</p>
            <p className=" mt-4">
              We offer refunds under the following circumstances:
            </p>
            <ul className="list-disc list-inside">
              <li>
                If the service is not delivered as promised due to an error on
                our end.
              </li>
              <li>
                If a technical issue caused by our platform prevents you from
                accessing the features you paid for, and the issue cannot be
                resolved within a reasonable timeframe.
              </li>
              <li>
                If you cancel your subscription within the refund period
                outlined below.
              </li>
            </ul>
          </div>
          <div>
            <p className="mt-4 font-bold">Refund Period</p>
            <p className=" mt-4">
              Refund requests must be made within 14 days of the payment date.
              Requests made after this period will not be eligible for a refund.
            </p>
          </div>
          <div>
            <p className="mt-4 font-bold">Non-Refundable Cases</p>
            <p className=" mt-4">
              Refunds will not be granted under the following conditions:
            </p>
            <ul className="list-disc list-inside">
              <li>
                If you change your mind after purchasing a subscription or
                service.
              </li>
              <li>
                If you fail to use the service during the subscription period.
              </li>
              <li>
                If the issue is caused by third-party software or tools not
                affiliated with our platform.
              </li>
            </ul>
          </div>
          <div>
            <p className="mt-4 font-bold">Refund Process</p>
            <p className=" mt-4">
              To request a refund, please follow these steps:
            </p>
            <ul className="list-disc list-inside">
              <li>Contact our support team at tesic@notify-me.rs.</li>
              <li>
                Provide your payment receipt, order ID, and a detailed
                explanation of the issue.
              </li>
              <li>
                Our team will review your request and respond within 3-5
                business days.
              </li>
              <li>
                If your request is approved, the refund will be processed to
                your original payment method within 7-10 business days.
              </li>
            </ul>
          </div>
          <div>
            <p className="mt-4 font-bold">Contact Us</p>
            <p className=" mt-4">
              If you have any questions about this Refund Policy or require
              assistance, please reach out to us at: tesic@notify-me.rs
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
