import React, { useEffect, useState } from 'react';
import { FaDiscord, FaTelegramPlane } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import * as api from '../../api';
import LoadingBar from '../../components/LoadingBar';
import { DiscordData, TelegramData } from '../../api/types';
import Popup from 'reactjs-popup';
import EmailPopup from './popups/EmailPopup';
import DiscordPopup from './popups/DiscordPopup';
import useApiCall from '../../hooks/UseApiCall';
import { useBillingInfo } from '../../context/BillingInfoContext';
import { PlanId } from '../../types';
import TelegramPopup from './popups/TelegramPopup';

export default function Notifications() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);
  const [discordData, setDiscordData] = useState({
    enabled: false,
    webhook: '',
  });
  const [telegramData, setTelegramData] = useState({
    enabled: false,
    token: '',
  });

  const apiCall = useApiCall(setWaitingForServerResponse);
  const { billingInfo } = useBillingInfo();

  useEffect(() => {
    (async () => {
      const discordResponse = await apiCall(api.getDiscordData(), undefined);
      if (discordResponse.error) {
        return;
      }
      const discordData = discordResponse.data as DiscordData;

      const telegramResponse = await apiCall(api.getTelegramData(), undefined);
      if (telegramResponse.error) {
        return;
      }
      const telegramData = telegramResponse.data as TelegramData;

      setWaitingForServerResponse(true);

      setDiscordData({
        enabled: discordData.enabled,
        webhook: discordData.discord_webhook,
      });
      setTelegramData({
        enabled: telegramData.enabled,
        token: telegramData.telegram_token,
      });
      setWaitingForServerResponse(false);
    })();
  }, [shouldRerender]);

  //? Used so we can force rerender from child component if needed (e.g when updating notifications info)
  const handleReRender = () => {
    setShouldRerender(!shouldRerender);
  };

  const getButtonStyle = (isActive: boolean) => {
    return isActive
      ? 'btn btn-primary cursor-pointer text-white my-2 mx-4'
      : 'btn btn-outline btn-primary cursor-pointer text-white my-2 mx-4';
  };

  if (waitingForServerResponse) {
    return (
      <>
        <p className="nm-default-page-heading">Notifications</p>
        <div className="m-auto text-center mt-28">
          <LoadingBar isLoading={true} />
        </div>
      </>
    );
  }

  return (
    <>
      <p className="nm-default-page-heading">Notifications</p>
      <div className="rounded-xl max-w-2xl mx-auto bg-main-backgroundFooter mt-8">
        <div className="p-6 m-auto">
          <p className="text-xl">Destinations</p>
          <p className="text-sm text-main-footerLinks pt-4">
            Choose where you want to receive
            <br />
            notifications.
          </p>
          <div className="py-4 lg:flex lg:mt-6 mx-auto">
            <Popup
              modal
              overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
              trigger={
                <button className={getButtonStyle(true)}>
                  <div className="px-6">
                    <IoMdMail
                      className="text-center mr-2 inline-block"
                      size={24}
                    />
                    <span className="inline-block">Email</span>
                  </div>
                </button>
              }
            >
              <EmailPopup />
            </Popup>
            <Popup
              modal
              overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
              trigger={
                <button className={getButtonStyle(discordData.enabled)}>
                  <div className="px-6">
                    <FaDiscord
                      className="text-center mr-2 inline-block"
                      size={24}
                    />
                    <span className="inline-block">Discord</span>
                  </div>
                </button>
              }
            >
              <DiscordPopup
                discordData={discordData}
                shouldRender={shouldRerender}
                handleReRender={handleReRender}
                isFree={
                  billingInfo !== undefined
                    ? billingInfo.planIs(PlanId.DEFAULT)
                    : false
                }
              />
            </Popup>
            <Popup
              modal
              overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
              trigger={
                <button className={getButtonStyle(telegramData.enabled)}>
                  <div className="px-6">
                    <FaTelegramPlane
                      className="text-center mr-2 inline-block"
                      size={24}
                    />
                    <span className="inline-block">Telegram</span>
                  </div>
                </button>
              }
            >
              <TelegramPopup
                telegramData={telegramData}
                shouldRender={shouldRerender}
                handleReRender={handleReRender}
                isFree={
                  billingInfo !== undefined
                    ? billingInfo.planIs(PlanId.DEFAULT)
                    : false
                }
              />
            </Popup>
          </div>
        </div>
      </div>
    </>
  );
}
