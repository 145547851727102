import { jwtDecode } from 'jwt-decode';
import { clearStorage, getAccessToken } from './storage';

export const accessTokenHasExpired = () => {
  const token = getAccessToken();

  if (!token) {
    return true;
  }
  let tokenExpirationTime = 0;
  try {
    tokenExpirationTime = jwtDecode(token).exp;
  } catch {
    return true;
  }
  const currentTime = Math.round(new Date().getTime() / 1000);

  return currentTime >= tokenExpirationTime;
};

export const isAuthenticated = () => {
  const hasExpired = accessTokenHasExpired();
  if (hasExpired) {
    clearStorage();
    return false;
  }
  return true;
};

export const getEmailFromToken = () => {
  const token = getAccessToken();

  try {
    return jwtDecode(token).identity;
  } catch {
    return undefined;
  }
};
