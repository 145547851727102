import React from 'react';
import HelmetComponent from '../../components/HelmetComponent';
import MainSection from './MainSection';
import { Route, Routes } from 'react-router-dom';
import {
  affiliateLinksPath,
  canyonBikesPath,
  monitorCompetitorWebsiteChangesStepByStepGuidePath,
  navigatingTheWorldOfWebsiteMonitoringPath,
  monitorWebsiteContentChangesEffectivelyPath,
  competitiveIntelligenceSystemUserGuidePath,
  trackPriceChangesOnWebsitesEssentialsTipsPath,
  webPageWatcherEsseentialsPath,
  emailNotificationServicesForProductivityPath,
  checkWebsiteStatusOnlineABegginersGuidePath,
  onlineMonitoringToolsABeginnersGuidePath,
  monitorWebsiteChangesABeginnersGuidePath,
  competitiveIntelligenceBasicsPath,
  automateWebsiteChangeAlertsWithTelegramApiPath,
  pushNotificationPricingGuide2024Path,
  eightEthicalWebScrapingBestPractices2024Path,
  webChangeTrackingAutomatedVsManualMethodsPath,
  realTimePriceAlertsGuideForEcommerce2024Path,
  eCommercePriceMonitoringCaseStudiesAndGuide2024Path,
} from './paths';
import CanyonBikeSales from './articles/CanyonBikeSales';
import AffiliateLinks from './articles/AffiliateLinks';
import NavigatingTheWorldOfWebsiteMonitoring from './articles/NavigatingTheWorldOfWebsiteMonitoring';
import MonitorCompetitorWebsiteChangesStepByStepGuide from './articles/MonitorWebsiteCompetitorChangesStepByStepGuide';
import MonitorWebsiteContentChangedEffectively from './articles/MonitorWebsiteContentChangesEffectively';
import CompetitiveIntelligenceSystemUserGuide from './articles/CompetitiveIntelligenceSystemUserGuide';
import TrackPriceChangesOnWebsitesEssentialsTips from './articles/TrackPriceChangesOnWebsitesEssentialsTips';
import WebPageWatcherEssentials from './articles/WebPageWatcherEssentials';
import EmailNotificationServicesForProductivity from './articles/EmailNotificationServicesForProductivity';
import CheckWebsiteStatusOnlineABegginersGuide from './articles/CheckWebsiteStatusOnlineABeginnersGuide.tsx';
import OnlineMonitoringToolsABeginnersGuide from './articles/OnlineMonitoringToolsABeginnersGuide.tsx copy';
import MonitorWebsiteContentChangesABeginnersGuide from './articles/MonitorWebsiteContentChangesABeginnersGuide';
import CompetitiveIntelligenceBasics from './articles/CompetitiveIntelligenceBasics';
import AutomateWebsiteChangeAlertsWithTelegramApi from './articles/AutomateWebsiteChangeAlertsWithTelegramApi';
import PushNotificationPricingGuide2024 from './articles/PushNotificationPricingGuide2024';
import EightEthicalWebScrapingBestPractices2024 from './articles/EightEthicalWebScrapingBestPractices2024';
import WebChangeTrackingAutomatedVsManualMethods from './articles/WebChangeTrackingAutomatedVsManualMethods';
import RealTimePriceAlertsGuideForEcommerce2024 from './articles/RealTimePriceAlertsGuideForEcommerce2024';
import ECommercePriceMonitoringCaseStudiesAndGuide2024 from './articles/ECommercePriceMonitoringCaseStudiesAndGuide2024';

export default function Blog() {
  return (
    <div className="max-w-7xl m-auto">
      <HelmetComponent
        title="Notify Me | Website change monitoring, tracking and alerts"
        description="Stay ahead with notify-me.rs! Discover the latest updates, trends, and insights on our blog. Elevate your notification game with notify-me.rs blog – because tracking sites has never been this effortless!"
        canonicalLink="/blog"
      />

      <Routes>
        <Route path="" element={<MainSection />} />
        <Route path={canyonBikesPath} element={<CanyonBikeSales />} />
        <Route path={affiliateLinksPath} element={<AffiliateLinks />} />
        <Route
          path={navigatingTheWorldOfWebsiteMonitoringPath}
          element={<NavigatingTheWorldOfWebsiteMonitoring />}
        />
        <Route
          path={monitorCompetitorWebsiteChangesStepByStepGuidePath}
          element={<MonitorCompetitorWebsiteChangesStepByStepGuide />}
        />
        <Route
          path={monitorWebsiteContentChangesEffectivelyPath}
          element={<MonitorWebsiteContentChangedEffectively />}
        />
        <Route
          path={competitiveIntelligenceSystemUserGuidePath}
          element={<CompetitiveIntelligenceSystemUserGuide />}
        />
        <Route
          path={trackPriceChangesOnWebsitesEssentialsTipsPath}
          element={<TrackPriceChangesOnWebsitesEssentialsTips />}
        />
        <Route
          path={webPageWatcherEsseentialsPath}
          element={<WebPageWatcherEssentials />}
        />
        <Route
          path={emailNotificationServicesForProductivityPath}
          element={<EmailNotificationServicesForProductivity />}
        />
        <Route
          path={checkWebsiteStatusOnlineABegginersGuidePath}
          element={<CheckWebsiteStatusOnlineABegginersGuide />}
        />
        <Route
          path={onlineMonitoringToolsABeginnersGuidePath}
          element={<OnlineMonitoringToolsABeginnersGuide />}
        />
        <Route
          path={monitorWebsiteChangesABeginnersGuidePath}
          element={<MonitorWebsiteContentChangesABeginnersGuide />}
        />
        <Route
          path={competitiveIntelligenceBasicsPath}
          element={<CompetitiveIntelligenceBasics />}
        />
        <Route
          path={automateWebsiteChangeAlertsWithTelegramApiPath}
          element={<AutomateWebsiteChangeAlertsWithTelegramApi />}
        />
        <Route
          path={pushNotificationPricingGuide2024Path}
          element={<PushNotificationPricingGuide2024 />}
        />
        <Route
          path={eightEthicalWebScrapingBestPractices2024Path}
          element={<EightEthicalWebScrapingBestPractices2024 />}
        />
        <Route
          path={webChangeTrackingAutomatedVsManualMethodsPath}
          element={<WebChangeTrackingAutomatedVsManualMethods />}
        />
        <Route
          path={realTimePriceAlertsGuideForEcommerce2024Path}
          element={<RealTimePriceAlertsGuideForEcommerce2024 />}
        />
        <Route
          path={eCommercePriceMonitoringCaseStudiesAndGuide2024Path}
          element={<ECommercePriceMonitoringCaseStudiesAndGuide2024 />}
        />
      </Routes>
    </div>
  );
}
