/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import LoadingBar from '../../components/LoadingBar';
import { useNavigate } from 'react-router';
import * as api from '../../api';
import HelmetComponent from '../../components/HelmetComponent';
import { useFormik } from 'formik';
import { addNewSiteValidationSchema } from './validation';
import { v4 as uuidv4 } from 'uuid';
import TextArea from '../../components/TextArea';
import useApiCall from '../../hooks/UseApiCall';
import InputField from '../../components/InputField';
import CheckIntervalDropdown, {
  PlanToIntervalMap,
} from '../../components/CheckIntervalDropdown';
import { useBillingInfo } from '../../context/BillingInfoContext';
import { PlanId } from '../../types';

export default function AddNewSite() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const { billingInfo } = useBillingInfo();

  const navigate = useNavigate();
  const [keywordsEnabled, setKeywordsEnabled] = useState(false);
  const apiCall = useApiCall(setWaitingForServerResponse);

  const currentPlan =
    billingInfo && billingInfo.id ? billingInfo.PlanId : PlanId.DEFAULT;
  const [scrapeInterval, setScrapeInterval] = useState<number>(
    PlanToIntervalMap[currentPlan],
  );

  console.log('scrapeInterval', scrapeInterval);

  const formik = useFormik({
    initialValues: {
      siteUrl: '',
      siteName: '',
      keywords: '',
    },
    validationSchema: addNewSiteValidationSchema,
    onSubmit: async (values) => {
      const keywordsForSending = keywordsEnabled ? values.keywords : '';
      onConfirm(uuidv4(), values.siteName, values.siteUrl, keywordsForSending);
    },
  });

  const onConfirm = async (
    id: string,
    siteName: string,
    siteUrl: string,
    keywords: string,
  ) => {
    const newSite = { id, siteName, siteUrl, keywords, scrapeInterval };

    console.log('newSite', newSite);

    await apiCall(
      api.addSite(newSite),
      "Success! We'll send you an email as soon as we access the site.",
    );
    navigate('/overview');
  };

  if (waitingForServerResponse) {
    return (
      <div className="nm-default-page min-h-screen">
        <HelmetComponent
          title="Add new site | Notify Me"
          description="Add new site page for Notify Me."
          canonicalLink="/add_new_site"
        />
        <div className="m-auto text-center mt-28">
          <LoadingBar isLoading={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <HelmetComponent
        title="Add new site | Notify Me"
        description="Add new site page for Notify Me."
        canonicalLink="/add_new_site"
      />
      <div className="flex lg:min-h-screen justify-center items-center">
        <form
          className="bg-main-footerLinks bg-opacity-40 p-8 border rounded-xl border-main-letters max-w-sm w-full"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center">
              Add new site
            </h1>
          </div>
          <InputField
            label="site url"
            id="siteUrl"
            type="siteUrl"
            name="siteUrl"
            value={formik.values.siteUrl}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.siteUrl && formik.errors.siteUrl
                ? formik.errors.siteUrl
                : undefined
            }
          />
          <InputField
            label="site name"
            id="siteName"
            type="siteName"
            name="siteName"
            value={formik.values.siteName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.siteName && formik.errors.siteName
                ? formik.errors.siteName
                : undefined
            }
          />

          <div className="">
            <input
              type="checkbox"
              id="checkbox"
              className="mr-2"
              onClick={() => setKeywordsEnabled(!keywordsEnabled)}
            />
            <label htmlFor="checkbox" className="text-sm">
              I want to track only changes with specific words
            </label>
          </div>
          <div className={keywordsEnabled ? '' : 'hidden'}>
            <TextArea
              rows={4}
              placeholder="Input words you want to track separated by space"
              label=""
              id="keywords"
              name="keywords"
              value={formik.values.keywords}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.keywords && formik.errors.keywords
                  ? formik.errors.keywords
                  : undefined
              }
            />
          </div>
          <div className="mt-4">
            <CheckIntervalDropdown
              scrapeInterval={scrapeInterval}
              setScrapeInterval={setScrapeInterval}
            />
          </div>

          <div className="mt-4">
            <button className="btn btn-block btn-primary" type="submit">
              Add Site
            </button>
          </div>
          <div className="text-center">
            <div className="mt-4">
              <LoadingBar isLoading={waitingForServerResponse} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
