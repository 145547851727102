import React, { useEffect, useState } from 'react';
import {
  AiOutlinePlus,
  AiOutlineDashboard,
  AiOutlineBell,
  AiOutlineUser,
  AiOutlineCreditCard,
  AiOutlineLogout,
  AiOutlineEdit,
} from 'react-icons/ai';
import { MdUpgrade } from 'react-icons/md';

import { useNavigate } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { useBillingInfo } from '../../context/BillingInfoContext';

export default function Sidebar() {
  const navigate = useNavigate();
  const authContext = useAuth();

  const [hasFreePlan, setHasFreePlan] = useState(false);

  const { billingInfo } = useBillingInfo();
  useEffect(() => {
    (async () => {
      if (!billingInfo) {
        return;
      }
      setHasFreePlan(billingInfo.isFreePlan());
    })();
  }, [billingInfo]);

  const onLogout = () => {
    authContext.logoutUser('/login', false);
    return;
  };

  return (
    <div className="hidden lg:flex min-h-full 3xl:w-72 bg-main-footerLinks bg-opacity-[0.04] px-4 py-2">
      <div className="flex w-full flex-col">
        <ul className="menu">
          <li className="menu-title">General</li>
          <li
            className="btn btn-accent text-white "
            onClick={() => navigate('/add_new_site')}
          >
            <div className="flex p-3 mr-auto">
              <AiOutlinePlus className="text-center" size={20} />
              Add new site
            </div>
          </li>
          <li
            className="btn btn-accent text-white mt-4"
            onClick={() => navigate('/overview')}
          >
            <div className="flex p-3 mr-auto">
              <AiOutlineDashboard className="text-center" size={20} />
              Overview
            </div>
          </li>
          <li
            className="btn btn-accent text-white mt-4"
            onClick={() => navigate('/account_settings/notifications')}
          >
            <div className="flex p-3 mr-auto">
              <AiOutlineBell className="text-center" size={20} />
              Notifications
            </div>
          </li>
        </ul>
        <ul className="menu mt-4">
          <li className="menu-title">Account</li>
          {hasFreePlan ? (
            <>
              <li
                className="btn btn-primary text-black "
                onClick={() => navigate('/pricing')}
              >
                <div className="flex p-3 mr-auto">
                  <MdUpgrade className="text-center" size={20} />
                  Upgrade Plan
                </div>
              </li>
              <li
                className="btn btn-accent text-white mt-4"
                onClick={() => navigate('/account_settings/my_account')}
              >
                <div className="flex p-3 mr-auto">
                  <AiOutlineUser className="text-center" size={20} />
                  My Account
                </div>
              </li>
            </>
          ) : (
            <li
              className="btn btn-accent text-white"
              onClick={() => navigate('/account_settings/my_account')}
            >
              <div className="flex p-3 mr-auto">
                <AiOutlineUser className="text-center" size={20} />
                My Account
              </div>
            </li>
          )}
          <li
            className="btn btn-accent text-white mt-4"
            onClick={() => navigate('/pricing')}
          >
            <div className="flex p-3 mr-auto">
              <AiOutlineCreditCard className="text-center" size={20} />
              {'Billing & Plans'}
            </div>
          </li>
          <li
            className="btn btn-accent text-white mt-4"
            onClick={() => navigate('/send_review')}
          >
            <div className="flex p-3 mr-auto">
              <AiOutlineEdit className="text-center" size={20} />
              Send Review
            </div>
          </li>

          <li className="btn btn-accent text-white mt-4" onClick={onLogout}>
            <div className="flex p-3 mr-auto">
              <AiOutlineLogout className="text-center" size={20} />
              Log Out
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
