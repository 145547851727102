/* eslint-disable no-unused-vars */
import React from 'react';
import { PlanId } from '../../types';
import { useBillingInfo } from '../../context/BillingInfoContext';

interface Props {
  setScrapeInterval: React.Dispatch<React.SetStateAction<number>>;
  scrapeInterval: number | undefined;
}

export enum DropdownOptions {
  FIFTEEN_MINUTES = 15 * 60,
  TWO_HOURS = 120 * 60,
  EIGHT_HOURS = 480 * 60,
  THREE_HOURS = 180 * 60,
}

export const PlanToIntervalMap: Record<PlanId, number> = {
  [PlanId.DEFAULT]: DropdownOptions.EIGHT_HOURS,
  [PlanId.EARLY_ADOPTER]: DropdownOptions.THREE_HOURS,
  [PlanId.STANDARD]: DropdownOptions.TWO_HOURS,
  [PlanId.DEV_INFINITE]: DropdownOptions.TWO_HOURS,
  [PlanId.LIFETIME]: DropdownOptions.TWO_HOURS,
  [PlanId.ESSENTIALS]: DropdownOptions.TWO_HOURS,
};

export default function CheckIntervalDropdown(props: Props) {
  const { billingInfo } = useBillingInfo();
  const shouldDisableButton = () => {
    return (
      billingInfo &&
      billingInfo.isFreePlan !== undefined &&
      billingInfo?.isFreePlan()
    );
  };

  const displayUpgradeText = () => {
    if (shouldDisableButton()) {
      return '(available on paid plans)';
    }
    return '';
  };

  const calculateDropdownOptions = () => {
    const options = [
      {
        value: DropdownOptions.FIFTEEN_MINUTES,
        text: `15min ${displayUpgradeText()}`,
        disabled: shouldDisableButton(),
      },
      {
        value: DropdownOptions.TWO_HOURS,
        text: `2hr ${displayUpgradeText()}`,
        disabled: shouldDisableButton(),
      },
      {
        value: DropdownOptions.THREE_HOURS,
        text: `3hr ${displayUpgradeText()}`,
        disabled: shouldDisableButton(),
      },
      {
        value: DropdownOptions.EIGHT_HOURS,
        text: `8hr`,
        disabled: false,
      },
    ];
    return options.sort((a, b) => a.value - b.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const interval = parseInt(event.target.value);
    props.setScrapeInterval(interval);
    console.log('Handle change interval', interval);
  };

  return (
    <div className="">
      <div className="label">
        <span className="label-text">Select check interval:</span>
      </div>
      <select
        className="select w-full max-w-xs bg-main-letters text-main-background"
        onChange={handleChange}
        value={props.scrapeInterval}
      >
        {calculateDropdownOptions().map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}
