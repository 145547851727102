import React, { useEffect, useState } from 'react';
import logoImg from '../../../img/icons/nm_logo/main_logo.svg';
import { useNavigate } from 'react-router';
import { BiLinkExternal } from 'react-icons/bi';
import { useBillingInfo } from '../../../context/BillingInfoContext';
import { Link } from 'react-router-dom';
import {
  AiOutlineBell,
  AiOutlineCreditCard,
  AiOutlineDashboard,
  AiOutlineEdit,
  AiOutlineLogout,
  AiOutlinePlus,
  AiOutlineUser,
} from 'react-icons/ai';
import { MdUpgrade } from 'react-icons/md';
import { useAuth } from '../../../context/AuthContext';

export default function Navbar() {
  const navigate = useNavigate();
  const authContext = useAuth();

  const [hasFreePlan, setHasFreePlan] = useState(false);

  const { billingInfo } = useBillingInfo();

  useEffect(() => {
    (async () => {
      if (!billingInfo) {
        return;
      }
      setHasFreePlan(billingInfo.isFreePlan());
    })();
  }, [billingInfo]);

  const onLogout = () => {
    authContext.logoutUser('/login', false);
    return;
  };

  return (
    <div className="">
      <nav className="navbar bg-main-backgroundFooter">
        <div className="navbar-start">
          <div className="w-16 cursor-pointer" onClick={() => navigate('/')}>
            <img alt="Tailwind CSS Navbar component" src={logoImg} />
          </div>
          <p className="invisible lg:visible font-heading text-main-letters text-2xl">
            <Link to="/"> Notify Me</Link>
          </p>
        </div>
        <div className="navbar-end">
          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost lg:hidden bg-white text-main-backgroundFooter"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-main-backgroundFooter rounded-box w-52"
            >
              <li className="menu-title">General</li>

              {hasFreePlan ? (
                <>
                  <li className=" bg-main-letters p-1.5 rounded-lg text-main-background">
                    <Link to="/pricing">
                      <MdUpgrade className="text-center" size={20} />
                      Upgrade Plan
                    </Link>
                  </li>
                  <li className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5 mt-2">
                    <Link to="/add_new_site">
                      <AiOutlinePlus className="text-center" size={20} />
                      Add New Site
                    </Link>
                  </li>
                </>
              ) : (
                <li className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5">
                  <Link to="/add_new_site">
                    <AiOutlinePlus className="text-center" size={20} />
                    Add New Site
                  </Link>
                </li>
              )}
              <li className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5 mt-2">
                <Link to="/overview">
                  <AiOutlineDashboard className="text-center" size={20} />
                  Overview
                </Link>
              </li>
              <li className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5 mt-2">
                <Link to="/account_settings/notifications">
                  <AiOutlineBell className="text-center" size={20} />
                  Notifications
                </Link>
              </li>

              <li className="menu-title mt-4">Account</li>
              <li className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5">
                <Link to="/account_settings/my_account">
                  <AiOutlineUser className="text-center" size={20} />
                  My account
                </Link>
              </li>
              <li className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5 mt-2">
                <Link to="/pricing">
                  <AiOutlineCreditCard className="text-center" size={20} />
                  {'Billing & Plans'}
                </Link>
              </li>
              <li className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5 mt-2">
                <Link to="/send_review">
                  <AiOutlineEdit className="text-center" size={20} />
                  Send Review
                </Link>
              </li>
              <li
                className="bg-main-footerLinks bg-opacity-25 rounded-lg p-1.5 mt-2"
                onClick={onLogout}
              >
                <span>
                  <AiOutlineLogout className="text-center" size={20} />
                  Log Out
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        {hasFreePlan ? (
          <div
            className="text-center bg-main-letters text-main-backgroundFooter hover:cursor-pointer hover:underline"
            onClick={() => navigate('/pricing')}
          >
            <p className="font-bold p-2">
              Upgrade plan now{' '}
              <span>
                <BiLinkExternal
                  size={14}
                  className="inline-block mb-0.5 font-bold"
                />
              </span>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
