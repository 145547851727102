/* eslint-disable no-unused-vars */
import { BillingInfoResponse } from '../api/types';

export interface TrackedSite {
  id: string;
  siteName: string;
  siteUrl: string;
  keywords: string;
  isActive?: boolean;
  lastActiveDate?: string;
  scrapeInterval?: number;
}

export type TrackedSites = Array<TrackedSite>;

export interface SiteFromDB {
  url: string;
  name: string;
  is_active: boolean;
  keywords: string;
  last_active_date?: string;
  scrape_interval?: number;
}

export interface SiteFromPackage {
  name: string;
  url: string;
  keywords: string;
}

export enum PlanId {
  DEFAULT = 'default',
  EARLY_ADOPTER = 'early-adopter',
  ESSENTIALS = 'essentials-1',
  STANDARD = 'standard-1',
  DEV_INFINITE = 'dev-infinite',
  LIFETIME = 'lifetime-1',
}

export class BillingInfo implements BillingInfoResponse {
  name: string;
  price_usd: string;
  id: string;
  is_special?: boolean | undefined;
  ls_id: number;
  renews_at?: string | undefined;
  limits: {
    max_site_count: number;
    scrape_period: string;
    textornado_keywords: boolean;
    number_of_notification_channels: number;
  };
  urls?: { update_payment_method: string; customer_portal: string } | undefined;
  ends_at?: string | undefined;
  status: string;
  card_brand?: string | undefined;
  card_last_four?: string | undefined;

  constructor(info: BillingInfoResponse) {
    this.name = info.name;
    this.price_usd = info.price_usd;
    this.id = info.id;
    this.is_special = info.is_special;
    this.ls_id = info.ls_id;
    this.renews_at = info.renews_at;
    this.limits = info.limits;
    this.urls = info.urls;
    this.ends_at = info.ends_at;
    this.status = info.status;
    this.card_brand = info.card_brand;
    this.card_last_four = info.card_last_four;
  }

  isCanceled(): boolean {
    return this.status === 'canceled' || this.status === 'cancelled';
  }
  planIs(plan: PlanId): boolean {
    return this.id === plan;
  }
  isSpecial(): boolean {
    return this.is_special === true;
  }

  isFreePlan(): boolean {
    return this.planIs(PlanId.DEFAULT) || this.planIs(PlanId.EARLY_ADOPTER);
  }

  isPaidPlan(): boolean {
    return [PlanId.STANDARD, PlanId.ESSENTIALS, PlanId.LIFETIME].includes(
      this.id as PlanId,
    );
  }

  isLifetimePlan(): boolean {
    return this.planIs(PlanId.LIFETIME);
  }

  downgradePending(): boolean {
    if (this.ends_at && this.isCanceled()) {
      return true;
    }
    return false;
  }
  get updatePaymentMethodUrl(): string {
    if (!this.urls) {
      return '';
    }
    return this.urls.update_payment_method;
  }

  get customerPortalUrl(): string {
    if (!this.urls) {
      return '';
    }
    return this.urls.customer_portal;
  }

  get PlanId(): PlanId {
    return this.id as PlanId;
  }

  hasCard(): boolean {
    return this.card_brand !== undefined;
  }

  get cardName(): string {
    return (
      (this.card_brand as string).charAt(0).toUpperCase() +
      (this.card_brand as string).slice(1)
    );
  }

  get timeInterval(): string {
    if (this.planIs(PlanId.DEFAULT)) {
      return '8 hours';
    }
    if (this.planIs(PlanId.EARLY_ADOPTER)) {
      return '3 hours';
    }
    if (this.planIs(PlanId.ESSENTIALS)) {
      return '2 hours';
    }
    if (this.planIs(PlanId.STANDARD)) {
      return '2 hours';
    }
    if (this.planIs(PlanId.DEV_INFINITE)) {
      return '3 hours';
    }
    if (this.planIs(PlanId.LIFETIME)) {
      return '2 hours';
    }

    return '1 hour';
  }

  private formatDate(date?: string): string {
    if (!date) {
      return 'unable to determine date';
    }
    return new Date(Date.parse(date)).toLocaleString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  get renewsAt(): string {
    return this.formatDate(this.renews_at);
  }

  get endsAt(): string {
    return this.formatDate(this.ends_at);
  }
}
