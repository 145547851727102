import React, { MouseEventHandler } from 'react';

interface Props {
  imageSrc: string;
  title: string;
  description: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export default function FeaturedSection(props: Props) {
  return (
    <div className="grid lg:grid-cols-2 cursor-pointer" onClick={props.onClick}>
      <div className="m-auto p-4">
        <img
          className="border-2 border-white"
          src={props.imageSrc}
          alt="Featured Section Image"
        />
      </div>
      <div className="m-auto p-4">
        <h1 className="text-main-letters text-3xl md:text-4xl font-bold">
          {props.title}
        </h1>
        <p className="mt-4">{props.description}</p>
      </div>
    </div>
  );
}
